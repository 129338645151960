import { http } from "@edu/utils";

http.initConfig = {
  beforeRequestCallback(config) {
    config.url = config.url.startsWith("/") ? `/api${config.url}` : config.url;

    if (config.withOutHeader) {
      config.headers["X-Business-Header"] = null;
    }

    return config;
  },
  beforeErrorResponseCallback(error) {
    if (error?.status === 401 || error?.data?.code === "0002_11") {
      // 未登录状态
      // useUserStoreHook().logOut();
    }
  }
};

window.$globalHttp = http;
export function handleHeader(businessId?) {
  if (businessId) {
    http.setHeaders({
      "X-Business-Header": businessId
    });
    return;
  }
}
