import { doSignCourse, getCourseCates, getCourseInfo } from "@/api/course";
import { useUserStoreHook } from "@/store/modules/user";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref } from "vue";

const courseInfo = ref();
const currentTask = ref();
const loading = ref(false);
export const useCourse = () => {
  const init = async courseId => {
    loading.value = true;
    let course = null;
    const res = await getCourseInfo({
      id: courseId,
      userId: useUserStoreHook().userInfo?.userId
    });
    course = res.data;

    const cateRes = await queryCategorys(courseId);
    course["categorys"] = cateRes.data;
    courseInfo.value = course;
    loading.value = false;
  };

  const queryCategorys = async courseId => {
    // const cateRes = await getCourseCates({ id: courseId });

    return await getCourseCates({ id: courseId });
    // courseInfo.value["categorys"] = cateRes.data;
  };

  const updateCategorys = async courseId => {
    const cateRes = await queryCategorys(courseId);
    courseInfo.value["categorys"] = cateRes.data;
  };

  const checkLoginStatus = async (hideTip = false, msg = "") => {
    try {
      const flag = await useUserStoreHook().checkLogined();
      return flag;
    } catch (error) {
      if (hideTip) return false;
      ElMessageBox.confirm(msg || "您需要先完成登录才能继续操作。", "提示", {
        confirmButtonText: "前往登录",
        cancelButtonText: "取消",
        showClose: false,
        closeOnClickModal: false,
        type: "warning"
      })
        .then(() => {
          useUserStoreHook().doLogin();
        })
        .catch(() => {
          ElMessage.info("已取消操作");
        });
    }
  };

  const signCourse = async courseId => {
    if (!(await checkLoginStatus())) {
      return;
    }
    return new Promise(resolve => {
      doSignCourse({ courseId })
        .then(() => {
          ElMessageBox.alert("恭喜你，报名成功。", "提示", {
            confirmButtonText: "继续学习",
            type: "success",
            showClose: false,
            closeOnClickModal: false,
            callback: async () => {
              await init(courseId);
              resolve(true);
            }
          });
        })
        .catch(() => {
          resolve(false);
        });
    });
  };

  function setCurrentTask(task) {
    currentTask.value = task;
  }
  return {
    init,
    loading,
    courseInfo,
    currentTask,
    signCourse,
    checkLoginStatus,
    setCurrentTask,
    updateCategorys
  };
};
