export default {
  Version: "2.0.0",
  Title: "广联达教育",
  FixedHeader: true,
  HiddenSideBar: false,
  MultiTagsCache: false,
  KeepAlive: true,
  Locale: "zh",
  Layout: "vertical",
  Theme: "default",
  DarkMode: false,
  Grey: false,
  Weak: false,
  SidebarStatus: true,
  EpThemeColor: "#409EFF",
  ShowLogo: true,
  ShowModel: "smart",
  MenuArrowIconNoTransition: true,
  CachingAsyncRoutes: false,
  TooltipEffect: "light",
  ResponsiveStorageNameSpace: "responsive-"
};
