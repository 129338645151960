const IFrame = () => import("@/layout/frameView.vue");
const Layout = () => import("@/layout/index.vue");
import hostMap from "@/config/hostMap";

export default {
  name: "edu-gdep",
  path: "/gdep",
  component: Layout,
  meta: {
    title: "院校实训",
    showLink: true,
    rank: 2
  },
  children: [
    {
      path: "/gdep",
      name: "edu-gdep-index",
      component: IFrame,
      meta: {
        title: "院校实训",
        frameSrc: hostMap("gdep"),
        showLink: true
      }
    }
  ]
};
