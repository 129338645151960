const hostMaps = {
  build: {
    dev: "https://test-build.glodonedu.com",
    test: "https://test-build.glodonedu.com",
    prod: "https://build.glodonedu.com"
  },
  szpx: {
    dev: "https://test-szpx.glodonedu.com",
    test: "https://test-szpx.glodonedu.com",
    prod: "https://szpx.glodonedu.com"
  },
  gdep: {
    dev:
      "https://static-cdn.glodonedu.com/gdep_platform_APP_test/download/index.html?inner=1&t=" +
      new Date().getTime(),
    test:
      "https://static-cdn.glodonedu.com/gdep_platform_APP_test/download/index.html?inner=1&t=" +
      new Date().getTime(),
    prod:
      "https://gp.glodonedu.com/index.html?inner=1&t=" + new Date().getTime()
  },
  test: {
    dev: "",
    test: "",
    prod: ""
  },
  employee: {
    dev: "https://test-zhaopin.glodonedu.com?t=" + new Date().getTime(),
    test: "https://test-zhaopin.glodonedu.com?t=" + new Date().getTime(),
    prod: "https://zhaopin.glodonedu.com?t=" + new Date().getTime()
  },
  pm: {
    dev: "https://pm.glodonedu.com?inner=1&t=" + new Date().getTime(),
    test: "https://pm.glodonedu.com?inner=1&t=" + new Date().getTime(),
    prod: "https://pm.glodonedu.com?inner=1&t=" + new Date().getTime()
  }
};

const env = location.hostname.startsWith("dev-")
  ? "dev"
  : location.hostname.startsWith("local")
    ? "test"
    : location.hostname.startsWith("test-")
      ? "test"
      : location.hostname.startsWith("pre-")
        ? "pre"
        : "prod";
export default name => {
  return hostMaps[name][env];
};
