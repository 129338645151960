const Layout = () => import("@/layout/index.vue");
// const Frame = () => import("@/layout/frameView.vue");

export default {
  name: "edu-person",
  path: "/person",
  component: Layout,
  meta: {
    title: "个人中心",
    showLink: false,
    rank: 4
  },
  children: [
    {
      path: "/person/:path",
      name: "edu-person-sub",
      component: () => import("@/views/person/index.vue"),
      meta: {
        title: "个人中心"
      }
    }
  ]
};
