const Layout = () => import("@/layout/index.vue");
const Frame = () => import("@/layout/frameView.vue");
import hostMap from "@/config/hostMap";

export default {
  name: "edu-employee",
  path: "/employee",
  component: Layout,
  meta: {
    title: "就业服务",
    showLink: true,
    rank: 5
  },
  children: [
    {
      path: "/employee",
      name: "edu-employee-index",
      component: Frame,
      meta: {
        title: "就业服务",
        showLink: true,
        newWindow: true,
        frameSrc: hostMap("employee"),
        parentPath: "/skill"
      }
    }
  ]
};
