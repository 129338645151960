import { CheckLogin } from "@/api/user";
import NProgress from "@/utils/progress";
import { usePermissionStoreHook } from "@/store/modules/permission";
import { buildHierarchyTree } from "@/utils/tree";
import {
  RouteComponent,
  RouteRecordRaw,
  Router,
  createRouter
} from "vue-router";
import {
  ascending,
  getHistoryMode,
  formatTwoStageRoutes,
  formatFlatteningRoutes
} from "./utils";

import remainingRouter from "./remaining";

/** 原始静态路由（未做任何处理） */
const routes = [];

/** 自动导入全部静态路由，无需再手动引入！匹配 src/router/modules 目录（任何嵌套级别）中具有 .ts 扩展名的所有文件，除了 remaining.ts 文件
 * 如何匹配所有文件请看：https://github.com/mrmlnc/fast-glob#basic-syntax
 * 如何排除文件请看：https://cn.vitejs.dev/guide/features.html#negative-patterns
 */
const modules: Record<string, any> = import.meta.glob(
  ["./modules/**/*.ts", "!./modules/**/remaining.ts"],
  {
    eager: true
  }
);

Object.keys(modules).forEach(key => {
  routes.push(modules[key].default);
});
/** 导出处理后的静态路由（三级及以上的路由全部拍成二级） */
export const constantRoutes: Array<RouteRecordRaw> = buildHierarchyTree(
  ascending(routes.flat(Infinity))
);

/** 用于渲染菜单，保持原始层级 */
export const constantMenus: Array<RouteComponent> = ascending(
  routes.flat(Infinity)
).concat(...remainingRouter);

/** 不参与菜单的路由 */
export const remainingPaths = Object.keys(remainingRouter).map(v => {
  return remainingRouter[v].path;
});

/** 创建路由实例 */
export const router: Router = createRouter({
  history: getHistoryMode(),
  routes: constantRoutes as any,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      if (savedPosition) {
        return savedPosition;
      } else {
        resolve({ top: 0 });
      }
    });
  }
});

/** 重置路由 */
export function resetRouter() {
  router.getRoutes().forEach(route => {
    const { name, meta } = route;
    if (name && router.hasRoute(name) && meta?.backstage) {
      router.removeRoute(name);
      const nextRoutes = formatTwoStageRoutes(
        formatFlatteningRoutes(buildHierarchyTree(ascending(routes)))
      );
      router.options.routes = nextRoutes;
    }
  });
  usePermissionStoreHook().clearAllCachePage();
}

router.beforeEach(async (to: ToRouteType, _from, next) => {
  try {
    await CheckLogin();
  } catch (error) {
    console.log("未登录");
  }
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
