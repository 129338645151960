import { http } from "@edu/utils";
import Cookies from "js-cookie";

const headers = { "Content-Type": "multipart/form-data" };

const jsonp = url => {
  return new Promise(resolve => {
    window.jsonCallBack = result => {
      resolve(result);
    };
    const JSONP = document.createElement("script");
    JSONP.type = "text/javascript";
    JSONP.src = url;
    document.getElementsByTagName("head")[0].appendChild(JSONP);
    setTimeout(() => {
      document.getElementsByTagName("head")[0].removeChild(JSONP);
      resolve(false);
    }, 500);
  });
};

const getTicket = () => {
  return http
    .request<any>("post", "/api/auth/ticket/get")
    .then(res => {
      return res?.data?.ticket;
    })
    .catch(error => "");
};

/**
 * 单点登录
 */
export const loginByTicket = async () => {
  const ticket = await getTicket();
  return http
    .request<any>("get", `/api/auth/sso/login/ticket?ticket=${ticket}`)
    .then(res => {
      sessionStorage.setItem('zt_token', res?.data?.zt_token);
      return res;
    });
};

export const synchronizeCloudLogin = async () => {
  try {
    const ticket = await jsonp(
      "https://account.glodon.com/user_ticket?service_key=3px7XBpqtn3HqswIlWYMqad3VTSoGsJM&callback=jsonCallBack"
    );

    if (ticket) {
      await http.request(
        "get",
        `/api/auth/sso/login/casRedirect?redirectUrl=${location.href}&ticket=${ticket}`
      );
    }

    await loginByTicket();
    return;
  } catch (error) {
    console.log("getQFToken error", error);
    return;
  }
};
