// import { about } from "@/router/enums";
const Layout = () => import("@/layout/index.vue");

export default {
  path: "/course",
  redirect: "/course/index",
  component: Layout,
  meta: {
    title: "课程中心",
    rank: 1,
    showLink: true
  },
  // ext: JSON.stringify({ component: "Layout" }),
  children: [
    {
      path: "/course/index",
      name: "course-home",
      component: () => import("@/views/course/home/index.vue"),
      meta: {
        title: "课程中心",
        showLink: true,
        parentPath: "/course"
      }
    },
    {
      path: "/course/search",
      name: "course-search",
      component: () => import("@/views/course/search/index.vue"),
      meta: {
        title: "课程中心-列表"
      }
    },
    {
      path: "/course/:id",
      name: "course-detail",
      component: () => import("@/views/course/detail/index.vue"),
      meta: {
        title: "课程中心-课程详情"
      }
    },
    {
      path: "/specialized-training",
      name: "specialized-training",
      component: () => import("@/views/qifu/specialized.vue"),
      meta: {
        title: "能力测评-专题训练"
      }
    }
  ]
} as RouteConfigsTable;
