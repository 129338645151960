import { constantMenus } from "@/router";
import { filterNoPermissionTree, filterTree } from "@/router/utils";
import { store } from "@/store";
import { defineStore } from "pinia";
import { cacheType } from "./types";

export const usePermissionStore = defineStore({
  id: "pure-permission",
  state: () => ({
    // 静态路由生成的菜单
    constantMenus,
    // 缓存页面keepAlive
    cachePageList: [],
    // 整体路由生成的菜单（静态、动态）
    wholeMenus: []
  }),
  actions: {
    /** 组装整体路由生成的菜单 */
    handleWholeMenus() {
      this.wholeMenus = filterNoPermissionTree(
        filterTree(this.constantMenus.concat([]))
      );
    },
    cacheOperate({ mode, name }: cacheType) {
      const delIndex = this.cachePageList.findIndex(v => v === name);
      switch (mode) {
        case "refresh":
          this.cachePageList = this.cachePageList.filter(v => v !== name);
          break;
        case "add":
          this.cachePageList.push(name);
          break;
        case "delete":
          delIndex !== -1 && this.cachePageList.splice(delIndex, 1);
          break;
      }
    },
    /** 清空缓存页面 */
    clearAllCachePage() {
      this.cachePageList = [];
    }
  }
});

export function usePermissionStoreHook() {
  return usePermissionStore(store);
}
