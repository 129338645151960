import { GetUserInfo, doLogoutFn } from "@/api/user";
import { store } from "@/store";
import { resetRouter } from "@/router";
import { removeToken, sessionKey, type DataInfo } from "@/utils/auth";
import { storageSession } from "@pureadmin/utils";
import Cookies from "js-cookie";
import { cloneDeep, pullAll } from "lodash-es";
import { defineStore } from "pinia";
import { ElMessage } from "element-plus";
import type { userType } from "./types";

const passportHost =
  import.meta.env.VITE_APP_PASSPORT_HOST || window.location.origin;

export const useUserStore = defineStore({
  id: "pure-user",
  state: (): userType => ({
    // 用户名
    username:
      storageSession().getItem<DataInfo<number>>(sessionKey)?.username ?? "",
    // 页面级别权限
    roles: storageSession().getItem<DataInfo<number>>(sessionKey)?.role ?? [],
    // 判断登录页面显示哪个组件（0：登录（默认）、1：手机登录、2：二维码登录、3：注册、4：忘记密码）
    currentPage: undefined,
    userInfo: null,
    identity: "",
    logined: "0" // 解决 iframe 嵌套页面场景无法 Cookies.set 问题
  }),
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    }
  },
  actions: {
    SET_LOGINED(logined) {
      this.logined = logined;
    },

    SET_IDENTITY(roles) {
      if (
        pullAll(cloneDeep(roles), [
          "ROLE_TEACHER",
          "ROLE_COLLEGE",
          "ROLE_STUDENT"
        ])?.length
      ) {
        this.identity = "company";
      } else if (pullAll(cloneDeep(roles), ["ROLE_STUDENT"]).length) {
        this.identity = "teacher";
      } else {
        this.identity = "student";
      }
    },
    /** 存储用户名 */
    SET_USERNAME(username: string) {
      this.username = username;
    },
    async SET_USERINFO() {
      const resData: any = await GetUserInfo();
      const rules = resData.data?.role;
      this.SET_ROLES(rules);
      this.SET_IDENTITY(rules);

      this.userInfo = resData.data;
    },
    /** 存储角色 */
    SET_ROLES(roles: Array<string>) {
      this.roles = roles;
    },
    /** 存储登录页面显示哪个组件 */
    SET_CURRENTPAGE(value: number) {
      this.currentPage = value;
    },

    doLogin() {
      const loginUrl = `${passportHost}/login`;
      const redirectUrl = window.encodeURIComponent(window.location.href);
      window.location.href = `${loginUrl}?redirectUrl=${redirectUrl}`;
    },
    doRegister() {
      const registUrl = `${passportHost}/register`;
      const redirectUrl = window.encodeURIComponent(window.location.href);
      window.location.href = `${registUrl}?redirectUrl=${redirectUrl}`;
    },

    checkLogined() {
      return new Promise((resolve, reject) => {
        if (+Cookies.get("logined") || +useUserStoreHook().logined) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    },

    /** 前端登出（不调用接口） */
    async logOut(noFetch?) {
      this.username = "";
      this.roles = [];
      this.userInfo = null;
      removeToken();
      resetRouter();

      const currentPath = (
        window.location.href.includes("/error")
          ? `${window.location.origin}/portal-admin`
          : window.location.href
      ).replace("runtime=1", "runtime=0");

      const redirectUrl = encodeURIComponent(
        `${passportHost}/login?redirectUrl=${encodeURIComponent(currentPath)}`
      );

      try {
        !noFetch && (await doLogoutFn(Cookies.get("zt_token")));
        if (import.meta.env.VITE_APP_PERSONAL) {
          window.location.href = `${passportHost}/login?redirectUrl=${currentPath}`;
        } else {
          window.location.href = `https://account.glodon.com/cas/serviceLogout?service=3px7XBpqtn3HqswIlWYMqad3VTSoGsJM&return_to=${redirectUrl}`;
        }
      } catch (error) {
        ElMessage.error(error?.data?.message || "退出失败");
      }
    }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}
