import { http, handleDownloadDatas } from "@edu/utils";
import { ElMessage } from "element-plus";

export const getCourseInfo = (params?: object) => {
  return http.request<any>("post", "/course-service/cou/generalcourse/detail", {
    params
  });
};

export const getCourseCates = (params?: object) => {
  return http.request<any>(
    "post",
    "/course-service/cou/generalcourse/content",
    { params }
  );
};

const fetchToken = async params => {
  return http.request<any>(
    "post",
    "/course-service/cou/course/resource/preview/token",
    { params },
    { hideProgress: true }
  );
};

export const beginTask = async task => {
  const data = {
    courseTaskId: task.id,
    taskId: task.taskId,
    taskCode: task.taskCode
  };
  return http.request<any>("post", "/task-basic/task/learn/beginTask", {
    data
  });
};
export const submitTask = async data => {
  return http.request<any>(
    "post",
    "/task-basic/task/learn/submitActionItem",
    { data },
    { hideProgress: true }
  );
};

export const viewAnswerDetail = params => {
  return http.request<any>(
    "post",
    "/qu-answer-basic/quanswer/ansresult/query/answerScoreResult",
    { params }
  );
};

export const fetchExamInfo = async taskData => {
  return await http.request<any>(
    "post",
    "/qu-answer-basic/quanswer/answer/answerSepPaperData",
    {
      params: {
        recordCode: taskData?.recordCode,
        examId: taskData?.actionDetailInfo?.id
      }
    },
    { hideProgress: true }
  );
};

export const fetchWeikeInfo = async resource => {
  const { resourceId } = resource;
  const token = (await fetchToken({ resourceId }))?.data?.token;
  return http.request<any>(
    "post",
    "/course-service/cou/course/resource/preview/weike",
    { params: { token } },
    {
      hideProgress: true
    }
  );
};
export const fetchResourceInfo = async (task?) => {
  const params = { courseTaskId: task.id };
  return http.request<any>(
    "post",
    "/course-service/cou/course/resource/preview/paperData",
    { params },
    { hideProgress: true }
  );
};

// 课程报名
export const doSignCourse = (params?: object) => {
  return http.request<any>("post", "/task-basic/task/learn/signUp", { params });
};

// 企业测评
export const queryEnterprise = (params?: object) => {
  return http.request<any>(
    "get",
    "/zhao-pin/enterpriseTestController/queryEnterprise",
    { params }
  );
};

// 企业测评
export const testControllerSave = (data?: object) => {
  return http.request<any>("post", "/zhao-pin/enterpriseTestController/save", {
    data
  });
};

// 获取资源的基本信息 -- 判断是否有实操题的场景同时获取作答软件的相关信息
export const fetchResourceBasicInfo = (params?: object) => {
  return http.request<any>(
    "post",
    "/course-service/cou/generalcourse/task/gradeSoft",
    { params }
  );
};

export const fetchGradeSoftsInfo = data => {
  return http.request<any>(
    "post",
    "/quest-basic/question/graderule/manage/gradeDetail",
    { data }
  );
};

// 检测考试端版本是否为最新
export const checkGldgdepStatus = (params?: object) => {
  return http.request<any>(
    "post",
    "/config-basic/client/software/version/latest/version",
    { params }
  );
};

// 检测考试端版本是否为最新
export const fetchAnswerRecordList = (params?: object) => {
  return http.request<any>(
    "post",
    "/qu-answer-basic/quanswer/ansresult/query/answerRecordList",
    { params }
  );
};

export const previewReportApi = (params?: object) => {
  return http
    .request<any>(
      "get",
      "/qu-answer-basic/quanswer/gradereport/report/download",
      { params },
      { noHandleData: true, responseType: "blob" }
    )
    .then((res: any) => {
      console.log({ res });
      if (res.headers["content-disposition"]) {
        handleDownloadDatas(res);
        ElMessage.success("评分报告已下载至本地");
      } else {
        const reader: any = new FileReader();

        reader.readAsText(res.data, "utf-8");
        reader.onload = () => {
          if (!reader.result) {
            ElMessage.warning("抱歉，未找到相关的评分报告。");
            return;
          }
          const resObj = JSON.parse(reader.result);
          ElMessage.warning(resObj.message || "抱歉，未找到相关的评分报告。");
        };
        return;
      }
    });
};
