// import type { VueConstructor } from 'vue'
import "whatwg-fetch";
import WujieVue from "wujie-vue3";

import { useUserStoreHook } from "@/store/modules/user";

// const lifecycles = {
//   beforeLoad: (appWindow: any) => {
//     console.log(`${appWindow.__WUJIE.id} beforeLoad 生命周期`);
//   },
//   beforeMount: (appWindow: any) => {
//     console.log(`${appWindow.__WUJIE.id} beforeMount 生命周期`);
//   },
//   afterMount: (appWindow: any) => {
//     console.log(`${appWindow.__WUJIE.id} afterMount 生命周期`);
//   },
//   beforeUnmount: (appWindow: any) => {
//     console.log(`${appWindow.__WUJIE.id} beforeUnmount 生命周期`);
//   },
//   afterUnmount: (appWindow: any) => {
//     console.log(`${appWindow.__WUJIE.id} afterUnmount 生命周期`);
//   },
//   activated: (appWindow: any) => {
//     console.log(`${appWindow.__WUJIE.id} activated 生命周期`);
//   },
//   deactivated: (appWindow: any) => {
//     console.log(`${appWindow.__WUJIE.id} deactivated 生命周期`);
//   },
//   loadError: (url: string, e: Error) => {
//     console.log(`${url} 加载失败`, e);
//   }
// };

// const plugins = {
//   // Vite 需要处理样式加载逻辑，https://github.com/Tencent/wujie/issues/434#issuecomment-1614089196
//   fixViteStyle: {
//     patchElementHook(element, iframeWindow) {
//       if (element.nodeName === "STYLE") {
//         element.insertAdjacentElement = function (_position, ele) {
//           iframeWindow.document.head.appendChild(ele);
//         };
//       }
//     }
//   }
// };

// 携带登录态credentials必须为include
export function credentialsFetch(url, options) {
  return window.fetch(url, {
    ...options,
    headers: {
      //   ...options?.headers,
    },
    // "Access-Control-Allow-Credentials": true,
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Headers": "*",
    credentials: url.includes("//static-cdn.glodonedu.com") ? "omit" : "include"
  });
}

export function useWujie(app: any, router) {
  const { bus } = WujieVue;

  app.use(WujieVue);

  // console.log(import.meta.env, import.meta.env)

  // bus.$on("click", msg => {
  //   window.alert(msg);
  // });

  bus.$on("sub-route-change", (name, path) => {
    // console.log({ name, path });
    const mainName = `${name}`;
    const mainPath = `${path}`;
    const currentName = router.currentRoute.value.name;
    const currentPath = router.currentRoute.path;
    if (mainName === currentName && mainPath !== currentPath) {
      router.push({ path: mainPath });
    }
  });

  bus.$on("do-login", () => {
    useUserStoreHook()?.doLogin();
  });

  // const degrade =
  //   window.localStorage.getItem("degrade") === "true" ||
  //   !window.Proxy ||
  //   !window.CustomElementRegistry;
  // const props = {
  //   jump: name => {
  //     router.push({ name });
  //   }
  // };

  // setupApp({
  //   name: "edu-build",
  //   url: import.meta.env.VITE_VUE_APP_BUILD,
  //   alive: true,
  //   exec: true,
  //   props,
  //   degrade,
  //   fetch: credentialsFetch,
  //   ...lifecycles
  // });

  // setupApp({
  //   name: "edu-szpx",
  //   url: import.meta.env.VITE_VUE_APP_SZPX,
  //   alive: true,
  //   exec: true,
  //   props,
  //   degrade,
  //   plugins: [plugins.fixViteStyle],
  //   fetch: credentialsFetch,
  //   ...lifecycles
  // });

  // if (window.localStorage.getItem("preload") !== "false") {
  // @ts-ignore
  // preloadApp({ name: "edu-build" });
  // if (window.Proxy) {
  //   // @ts-ignore
  //   preloadApp({ name: "edu-szpx" });
  // }
  // }
}
