import { home } from "@/router/enums";
const Layout = () => import("@/layout/index.vue");

export default {
  path: "/",
  name: "Home",
  redirect: "/course",
  component: Layout,
  meta: {
    icon: "homeFilled",
    title: "课程中心",
    rank: home
  },
  children: []
} as RouteConfigsTable;
