const Layout = () => import("@/layout/index.vue");

export default {
  name: "edu-test",
  path: "/test",
  component: Layout,
  meta: {
    title: "企业测评",
    showLink: true,
    rank: 5
  },
  children: [
    {
      path: "/test",
      name: "edu-test-index",
      component: () => import("@/views/test/index.vue"),
      meta: {
        title: "企业测评",
        showLink: true
      }
    }
  ]
};
