import { handleHeader } from "./initHttp";
import useTable from "./useTable";
import { useWujie } from "@/hooks/index";
import { useI18n } from "@/plugins/i18n";
import { setupStore } from "@/store";
import { injectResponsiveStorage } from "@/utils/responsive";
import { MotionPlugin } from "@vueuse/motion";
import ElementPlus from "element-plus";
import { createApp, Directive } from "vue";
import App from "./App.vue";
import { getServerConfig } from "./config";
import router from "./router";

// import WujieVue from "wujie-vue3";

import "element-plus/dist/index.css";
import "@edu/components/dist/style.css";
// 引入重置样式
import "./style/reset.scss";
// 导入公共样式
import "./style/index.scss";
// 一定要在main.ts中导入tailwind.css，防止vite每次hmr都会请求src/style/index.scss整体css文件导致热更新慢的问题
import "./style/tailwind.css";
// 导入字体图标
import "vue3-video-play/dist/style.css"; // 引入css
import "./assets/iconfont/iconfont.css";
import "./assets/iconfont/iconfont.js";

// 自定义指令
import * as directives from "@/directives";

// 全局注册`@iconify/vue`图标库
import NavItem from "./components/Course/NavItem.vue";
import { IconifyIconOffline, IconifyIconOnline } from "./components/ReIcon";
import SectionItem from "./layout/components/Section.vue";
import { SchemaForm } from "@edu/components";

const app = createApp(App);

Object.keys(directives).forEach(key => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

app.component("IconifyIconOffline", IconifyIconOffline);
app.component("IconifyIconOnline", IconifyIconOnline);
app.component("SectionItem", SectionItem);
app.component("NavItem", NavItem);

const render = async (props?) => {
  handleHeader(props?.propDatas?.businessId);
  getServerConfig(app).then(async config => {
    setupStore(app);
    app.use(useTable);
    app.use(router);
    // app.use(WujieVue);
    useWujie(app, router);
    await router.isReady();
    injectResponsiveStorage(app, config);
    app.use(MotionPlugin).use(useI18n).use(ElementPlus).use(SchemaForm);

    const container = props?.container;
    if (container) {
      container.style.height = "100%";
    }

    app.mount(
      container ? container.querySelector("#course-app") : "#course-app"
    );
  });
};

render();
