const Layout = () => import("@/layout/index.vue");

export default {
  name: "edu-build",
  path: "/build",
  component: Layout,
  meta: {
    title: "毕有得",
    showLink: false,
    rank: 8
  },
  children: [
    {
      path: "/build/:path",
      name: "edu-build-sub",
      component: () => import("@/views/build/sub.vue"),
      meta: {
        title: "毕有得子页面",
        showLink: false,
        parentPath: "/build"
      }
    }
  ]
};
