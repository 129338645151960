const Layout = () => import("@/layout/index.vue");

export default {
  name: "edu-match",
  path: "/match",
  component: Layout,
  meta: {
    title: "赛事中心",
    showLink: true,
    rank: 4
  },
  children: [
    {
      path: "/match",
      name: "edu-match-index",
      component: () => import("@/views/match/index.vue"),
      meta: {
        title: "赛事中心",
        showLink: true
      }
    }
  ]
};
