const KnowledgeLayout = () => import("@/layout/KnowledgeLayout.vue");

export default {
  path: "/knowledge",
  name: "course-knowledge",
  component: KnowledgeLayout,
  meta: {
    title: "课程中心-学习",
    ext: JSON.stringify({ component: "KnowledgeLayout" })
    // noLayout: true,
    // transition: false
  },
  children: [
    {
      path: "/knowledge",
      name: "course-knowledge-index",
      component: () => import("@/views/course/knowledge/index.vue"),
      meta: {
        title: "课程中心-学习"
        // noLayout: true
      }
    }
  ]
} as RouteConfigsTable;
