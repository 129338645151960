// const Layout = () => import("@/layout/index.vue");

export default [
  {
    path: "/redirect",
    meta: {
      title: "",
      showLink: false,
      rank: 102
    },
    children: [
      {
        path: "/redirect/:path(.*)",
        name: "Redirect",
        component: () => import("@/layout/redirect.vue")
      }
    ]
  }
] as Array<RouteConfigsTable>;
