import { http } from "@edu/utils";
import Cookies from "js-cookie";
import { isNull } from "lodash-es";
import { synchronizeCloudLogin } from "@/api/glodon";
import { useUserStoreHook } from "@/store/modules/user";

export type UserResult = {
  success: boolean;
  data: {
    /** 用户名 */
    username: string;
    /** 当前登陆用户的角色 */
    roles: Array<string>;
    user?: any;
  };
};

export type LoginImgResult = {
  success: boolean;
  data: {
    url: string;
    lgToken: string;
  };
};

export type CheckImgResult = {
  success: boolean;
  message: string;
};

export const GetUserInfo = (data?: object) => {
  return http.request<any>("get", "/passport-agg/user/info", {
    data
  });
};

// 广联云单点登出
export const casLogout = async () => {
  const returnTo = encodeURIComponent(`${window.location.origin}/login`);
  return http.request<any>(
    "get",
    `https://account.glodon.com/cas/serviceLogout?service=3px7XBpqtn3HqswIlWYMqad3VTSoGsJM&return_to=${returnTo}`
  );
};
export const CheckLogin = async () => {
  try {
    await synchronizeCloudLogin();

    const retData: any = await http.request(
      "post",
      "/api/passport/user/online",
      {},
      { hideProgress: true }
    );

    const v = isNull(retData.data) || retData.data ? "1" : "0";
    Cookies.set("logined", v);
    useUserStoreHook().SET_LOGINED(v);
  } catch (error) {
    Cookies.set("logined", "0");
    useUserStoreHook().SET_LOGINED("0");
  }
};
export const doLogoutFn = async (token?) => {
  return http.request<UserResult>(
    "post",
    "/api/auth/oauth/logout",
    {},
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
};
